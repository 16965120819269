<template>
  <div :key="key">
          <default-app-bar > 
         <template v-slot:title>
          <div class="text-h3 mr-2"> Informe de Estudio </div>
         </template>
    
         
         <template v-slot:actions>
              <router-link  to="/admin/solicitudes/list-desembolsado"   color="primary" class="mr-2" >
                Volver
              </router-link>
              <a  small   color="primary" class="btn-margin0" elevation="1"  v-on:click="print">
                <v-icon small>mdi-file-pdf </v-icon> 
              </a>

         
         
         
         </template>
       </default-app-bar>

       <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card style="background-color: white !important">
        <v-card-title>
          <span class="headline">Nota</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea label="" v-model="note" required></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="blue darken-1" text @click="dialog = false">Cancelar</v-btn>
          
          <v-btn small class="mr-3" :loading="loading" color="primary" v-on:click="requestMoreInfo()"> Solicitar más Información </v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogVbno" persistent max-width="600px">
      <v-card style="background-color: white !important">
        <v-card-title>
          <span class="headline">Nota</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea label="" v-model="note" required></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="blue darken-1" text @click="dialogVbno = false">Cancelar</v-btn>
          <v-btn small  class="mr-3" :loading="loading"  color="primary" v-on:click="vtoBno()">Dar Visto Bueno</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDeclined" persistent max-width="600px">
      <v-card style="background-color: white !important">
        <v-card-title>
          <span class="headline">Nota</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea label="" v-model="note" required></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="blue darken-1" text @click="dialogDeclined = false">Cancelar</v-btn>
          <v-btn small  class="mr-3"  :loading="loading" color="warning" v-on:click="declined()">Rechazar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogMessage" persistent max-width="600px">
      <v-card style="background-color: white !important">
       
        <v-card-text>
          <v-container>
           La acción se realizo correctamente.
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small  class="mr-3"  :loading="loading" color="warning" v-on:click="ok()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div >
      <div class="d-flex justify-center my-3" v-if="showOptions" >
        <v-btn  class="" style="margin-left: 16px; margin-right: 1px" small  color="secondary" v-on:click="dialog = true">Solicitar Información</v-btn>
        <v-btn  class="" style="margin-right: 1px" small color="secondary" v-on:click="dialogVbno = true">Visto Bueno</v-btn>
        <v-btn  class="" small color="error" v-on:click="dialogDeclined = true">Rechazar</v-btn>
      </div>
    </div>

  <div v-html="body" class="pa-2" id="estudio-credito" style="overflow-x: auto" >
     

  </div>
</div>

</template>

<script>
import { Plugins } from '@capacitor/core';
const { Browser } = Plugins;


  export default {
    name: 'Study',
    data () {
      return {
        body: '',
        note: '',
        loading: false,
        dialog: false,
        dialogMessage: false,
        dialogDeclined: false,
        showOptions: false,
        dialogVbno: false,
        key: 0
        
      }
    },
    mounted(){
      //console.log(this.$route)
      if(this.$route.path.indexOf('study-pend') >= 0){
        this.showOptions = true;
      }
      const url = 'https://reportes.serprogreso.com/' + 'report/html/informe-estudio/' + this.$route.params.id;
      this.$http.getUrl(url).then((response) => {
        console.log(response)
        this.body = response;
        this.key++;
      })
    },
    props: ['info'],
    methods: {
      ok(){
        this.dialogMessage = false;
        this.$router.push('/admin/solicitudes/list-aprobado-inver');
      },
      requestMoreInfo(){
        this.loading = true;
        this.$http.post('api/admin/solicitudes/solicitud-devuelta-estudio-por-solicitud/'+ this.$route.params.id, { data: { obs: this.note }}, this, true, false).then( ( response) => {
          this.loading = false;
          //console.log(response);
          this.dialog = false;

          this.note = '';
          if(response.success){
            this.dialogMessage = true;
          }
          
          /*if(response.data.success){
            this.$modalsManager.open(this, NgMessage, { text: 'El registro se guardo correctamente', color: 'success'});
            this.$router.push(this.getUrlList());
          }*/
        }).catch(err => {
          this.loading = false;
        })
      },
      vtoBno(){
        this.loading = true;
        this.$http.post('api/admin/solicitudes/aprobar-solicitud-por-solicitud/'+ this.$route.params.id, { data: { obs: this.note }}, this, true, false).then( ( response) => {
          this.loading = false;
          this.note = '';
          this.dialogVbno = false;
          
          // console.log(response)
          if(response.success){
            this.dialogMessage = true;
          }
          /*if(response.data.success){
            this.$modalsManager.open(this, NgMessage, { text: 'El registro se guardo correctamente', color: 'success'});
            this.$router.push(this.getUrlList());
          }*/
        }).catch(err => {
          this.loading = false;
        })
      },
      declined(){
        this.loading = true;
        //const estado = this.item['form']['info_solicitud']['bus_tipo_estado_solicitud_id'];
        let apiUrlDeclined = 'rechazada'
        /*if(estado && estado['id'] == 8 ){
            apiUrlDeclined = 'rechazada-inver';
        }*/
        apiUrlDeclined = 'rechazada-inver';
        this.$http.post('api/admin/solicitudes/'+apiUrlDeclined+'/'+this.$route.params.id, { data: { obs: this.note }}, this, true, false).then( ( response) => {
          this.loading = false;
          this.note = '';
          this.dialogDeclined = false;
          if(response.success){
            this.dialogMessage = true;
          }
          /*if(response.data.success){
            this.$modalsManager.open(this, NgMessage, { text: 'El registro se guardo correctamente', color: 'success'});
            this.$router.push(this.getUrlList());
          }*/
        }).catch(err => {
          this.loading = false;
        })
      },
      print2(){
          const url = 'https://reportes.serprogreso.com/' + 'report/html/informe-estudio/' + this.$route.params.id;
          var win = window.open(url); 
       },
      print(){
        const url = 'https://reportes.serprogreso.com/' + 'report/html/informe-estudio/' + this.$route.params.id;
        Browser.open({ url });
          //window.open('https://reportes.serprogreso.com/' + 'report/html/informe-estudio/'+item.id, '_blank' )
 // const url = 'https://reportes.serprogreso.com/' + 'report/html/informe-estudio/'+item.id;
  //var win = window.open(url);   
  //  win.window.print(); 
    // Browser.open({ url });

       /* const w = window.open();
        var printContents = document.getElementById("estudio-credito").innerHTML;
        w.document.write(printContents);
        w.print();
w.close();*/
      }
    }
  }
</script>
